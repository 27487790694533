<template>
  <div class="__main_card_container">
    <div class="_main_card_header">
      <div class="__header_inner admin_header_padding">
        <div
          style="min-width: fit-content; padding-right: 10px"
          class="desktop-nav-sender">
          <button
            class="btn"
            :class="[
              $route.path == '/admin/sender_ids/pending'
                ? '__side_item_active'
                : '',
            ]"
            @click="
              $router.replace('/admin/sender_ids/pending').catch((err) => {})
            ">
            Sender IDs
          </button>
          <button
            class="btn"
            :class="[
              $route.path.includes('Reseller') ? '__side_item_active' : '',
            ]"
            @click="
              $router
                .replace('/admin/sender_ids/PendingReseller')
                .catch((err) => {})
            ">
            Reseller Sender IDs
          </button>
        </div>

        <div
          class="side_nav_wrapper"
          style="overflow-x: auto; min-height: 2rem">
          <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path == '/admin/sender_ids/PendingReseller'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/sender_ids/PendingReseller')
                  .catch((err) => {})
              ">
              Pending {{ `(${pendingCount})` }}
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/sender_ids/approvedReseller'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/sender_ids/approvedReseller')
                  .catch((err) => {})
              ">
              Approved {{ `(${approvedCount})` }}
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/sender_ids/DeclinedReseller'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/sender_ids/DeclinedReseller')
                  .catch((err) => {})
              ">
              Declined {{ `(${declinedCount})` }}
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/sender_ids/onholdReseller'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/sender_ids/onholdReseller')
                  .catch((err) => {})
              ">
              On Hold {{ `(${onHoldCount})` }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="main_card_body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  export default {
    components: {},
    data() {
      return {
        approvedCount: 0,
        pendingCount: 0,
        declinedCount: 0,
        onHoldCount: 0,
      };
    },
    computed: {
      getPageTitle() {
        const currentPage = this.$route.path;
        if (currentPage == "/admin/sender_ids/PendingReseller") {
          return "Reseller Sender ID Requests";
        }
        if (currentPage == "/admin/sender_ids/approvedReseller") {
          return "Reseller Approved Sender IDs";
        }
        if (currentPage == "/admin/sender_ids/DeclinedReseller") {
          return "Reseller Declined Sender IDs";
        }
        if (currentPage == "/admin/sender_ids/onHoldReseller") {
          return "Reseller On Hold Sender IDs";
        }
      },
      pending() {
        return store.getters["senderid/resellerPendingSenderIds"];
      },
      approved() {
        return store.getters["senderid/resellerApprovedSenderIds"];
      },
      declined() {
        return store.getters["senderid/resellerDeclinedSenderIds"];
      },
      onHold() {
        return store.getters["senderid/resellerOnHoldSenderIds"];
      },
    },
    mounted() {
      this.loading = true;
      store
        .dispatch("senderid/resellerSenderIDs", { status: 0, page: 1 })
        .then((data) => {
          this.approvedCount = data.data.count_approved;
          this.declinedCount = data.data.count_declined;
          this.pendingCount = data.data.count_pending;
          this.onHoldCount = data.data.count_onhold || 0;
        })
        .finally(() => (this.loading = false));
    },
  };
</script>

<style scoped>
  @media screen and (max-width: 1025px) {
    .desktop-nav-sender {
      display: none;
    }

    button {
      width: max-content;
    }
  }
</style>
