var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__main_card_container"},[_c('div',{staticClass:"_main_card_header"},[_c('div',{staticClass:"__header_inner admin_header_padding"},[_c('div',{staticClass:"desktop-nav-sender",staticStyle:{"min-width":"fit-content","padding-right":"10px"}},[_c('button',{staticClass:"btn",class:[
            _vm.$route.path == '/admin/sender_ids/pending'
              ? '__side_item_active'
              : '',
          ],on:{"click":function($event){_vm.$router.replace('/admin/sender_ids/pending').catch((err) => {})}}},[_vm._v(" Sender IDs ")]),_c('button',{staticClass:"btn",class:[
            _vm.$route.path.includes('Reseller') ? '__side_item_active' : '',
          ],on:{"click":function($event){_vm.$router
              .replace('/admin/sender_ids/PendingReseller')
              .catch((err) => {})}}},[_vm._v(" Reseller Sender IDs ")])]),_c('div',{staticClass:"side_nav_wrapper",staticStyle:{"overflow-x":"auto","min-height":"2rem"}},[_c('div',{staticClass:"__side_nav"},[_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/sender_ids/PendingReseller'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/sender_ids/PendingReseller')
                .catch((err) => {})}}},[_vm._v(" Pending "+_vm._s(`(${_vm.pendingCount})`)+" ")]),_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/sender_ids/approvedReseller'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/sender_ids/approvedReseller')
                .catch((err) => {})}}},[_vm._v(" Approved "+_vm._s(`(${_vm.approvedCount})`)+" ")]),_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/sender_ids/DeclinedReseller'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/sender_ids/DeclinedReseller')
                .catch((err) => {})}}},[_vm._v(" Declined "+_vm._s(`(${_vm.declinedCount})`)+" ")]),_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/sender_ids/onholdReseller'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/sender_ids/onholdReseller')
                .catch((err) => {})}}},[_vm._v(" On Hold "+_vm._s(`(${_vm.onHoldCount})`)+" ")])])])])]),_c('div',{staticClass:"main_card_body"},[_c('router-view')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }